import React, {useState} from 'react';

interface SearchInputProps {
    onSearch: (type: string, query: string) => void;
}

export const SearchSelectInput: React.FC<SearchInputProps> = ({onSearch}) => {
    const [placeholder, setPlaceholder] = useState('Buscar por');
    const [type, setType] = useState('');
    const [query, setQuery] = useState('');

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOptionText = e.target.options[e.target.selectedIndex].text;
        const value = e.target.options[e.target.selectedIndex].value;
        setPlaceholder('Buscar por ' + selectedOptionText);
        setType(value)
    };


    return (
        <div className="search-input-container">
            <img style={{
                height: 5,
                width: 10,
                position: 'absolute',
                left: 10,
                alignSelf: 'center',
                bottom: 10,
                cursor: 'pointer'
            }} src={require('../assets/select.png')}/>

            <select onChange={handleSelectChange} style={{
                position: 'absolute',
                cursor: 'pointer',
                opacity: 0,
            }}>
                <option value="codi">Código de estación</option>
                <option value="nom">Nombre de estación</option>
                <option value="cp">Código postal</option>
                <option value="ciutat">Población</option>
                <option value="direccio">Dirección</option>
                <option value="provincia">Provincia</option>
            </select>
            <input
                style={{paddingLeft: 35}}
                value={query}
                onChange={e => setQuery(e.target.value)}
                className="search-input"
                placeholder={placeholder}
            />
            <img onClick={()=>{ onSearch(type,query)}} style={{height: 17, width: 17, position: 'absolute', right: 10, alignSelf: 'center', bottom: 5, cursor:'pointer'}}
                 src={require('../assets/searchIcon.png')}/>
        </div>
    );
}

export default SearchSelectInput;
