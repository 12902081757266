import React, {useEffect, useRef} from 'react';
import {API_KEY} from "../utils";
import Autocomplete from "react-google-autocomplete";

interface SearchInputProps {
    placeholder?: string;
    onPlaceSelected: (place: any) => void;
    value: any;
}

export const AutocompleteInput: React.FC<SearchInputProps> = ({placeholder, onPlaceSelected, value}) => {
    const autocompleteRef = useRef<any>(null);
    useEffect(() => {
        if (autocompleteRef.current && autocompleteRef.current?.value) {
            autocompleteRef.current.value = value || '';
        }
    }, [value]);


    return (
        <div className="search-input-container">
            {!value ?
            <>
                <Autocomplete
                    apiKey={API_KEY}
                    onPlaceSelected={onPlaceSelected}
                    className="search-input"
                    placeholder={placeholder}
                    ref={autocompleteRef}
                />
                <img style={{height: 15, width: 15, position: 'absolute', right: 10, alignSelf: 'center', bottom: 5}}
                     src={require('../assets/searchIcon.png')}/>
            </> :
            <>
                <div style={{backgroundColor:'white', width:'100%', height:26,borderWidth:0, borderRadius:20, paddingLeft:10, paddingRight:30, marginTop:10, display:'flex', textAlign:'center', alignItems:'center'}}>
                    <span style={{fontSize:13}}>{value}</span>
                    <img onClick={()=>{
                        onPlaceSelected('')
                    }
                    } style={{height: 12, width: 12, position: 'absolute', right: 10, alignSelf: 'center', bottom: 6, cursor:'pointer'}}
                         src={require('../assets/closeIcon.png')}/>
                </div></>
            }



        </div>
    );
};

export default AutocompleteInput;
