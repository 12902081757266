import React from "react";

interface RoutesSelectedModalProps {
    origin: string;
    destination: string;
    onBackPress: () => void;
}

export const RoutesSelectedModal: React.FC<RoutesSelectedModalProps> = ({origin, destination,onBackPress}) => {
    return (
        <div className={'routes-modal-container'}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div onClick={onBackPress} style={{ cursor: 'pointer',paddingTop:10, paddingBottom:10}}>
                    <img style={{height: 12, marginRight: 20,}}
                         src={require('../assets/backIcon.png')}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <span style={{color: '#2F2A85'}}>
                       <span>desde: </span>
                       <span style={{fontWeight: 'bold'}}>{origin}</span>
                    </span>
                    <span style={{color: '#2F2A85'}}>
                       <span>a: </span>
                       <span style={{fontWeight: 'bold'}}>{destination}</span>
                    </span>
                </div>
            </div>
        </div>
    )
}
