import React, {useState} from "react";

interface SelectRadiusProps {
    radius: number;
    setRadius: (value: number) => void;
}

const SelectRadius: React.FC<SelectRadiusProps> = ({radius, setRadius}) => {
    const [isOpen, setIsOpen] = useState(false);

    const radiusOptions = [5, 10, 15, 20, 25, 30];

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectRadius = (value: number) => {
        setRadius(value);
        setIsOpen(false);
    };

    return (
        <div style={{width: '100%',marginTop:5,borderWidth:0, borderStyle:'none'}}>
            <div onClick={handleToggle} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer', padding: '10px', borderRadius: 50,backgroundColor:'white',marginBottom:5}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        style={{
                            width: 10,
                            marginRight: 10,
                            marginLeft: 15,
                            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.5s ease'
                        }}
                        src={require('../assets/chevronDownBlue.png')}
                        alt="Chevron Icon"
                    />
                    <span style={{color:'rgba(47, 42, 133, 0.5)'}}>Radio {radius} km</span>
                </div>
                <img style={{width: 20}} src={require('../assets/radio.png')} alt="Options Icon"/>
            </div>

            {isOpen && (
                <div style={{marginTop: 0, borderRadius: 10, padding: '10px', backgroundColor:'white', marginLeft: 15, position:'absolute', zIndex:5, width:'85%', borderWidth:1, borderColor:'grey', borderStyle:'solid'}}>
                    {radiusOptions.map(option => (
                        <div key={option} onClick={() => handleSelectRadius(option)} style={{cursor: 'pointer', padding: '5px 0', textAlign:'left', marginLeft:10,color:'rgba(47, 42, 133, 1)', fontSize:13}}>
                            Radio {option} km
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SelectRadius;
