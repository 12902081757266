import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import esTranslation from './locales/es.json';
import enTranslation from './locales/en.json';

const resources = {
    EN: {
        translation: esTranslation
    },
    ES: {
        translation: enTranslation
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'EN',
        fallbackLng: 'EN',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
