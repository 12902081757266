import React, {useState} from 'react';

interface RouteDetailProps {
    directionsResponse?: any;
    routeIndex: number;
    stationsQuantity: { index: number, quantity: number } | undefined;
    onShareClick: () => void;
    onEmailClick: () => void;
}

export const RouteDetail: React.FC<RouteDetailProps> = ({directionsResponse, routeIndex, stationsQuantity, onShareClick, onEmailClick}) => {
    const [isOpen, setIsOpen] = useState(false);

    const getManeuverIcon = (maneuver: string) => {
        let iconName;

        switch (maneuver) {
            case 'turn-slight-left':
                iconName = 'call_made';
                break;
            case 'turn-sharp-left':
                iconName = 'double_arrow';
                break;
            case 'uturn-left':
                iconName = 'subdirectory_arrow_left';
                break;
            case 'uturn-right':
                iconName = 'subdirectory_arrow_right';
                break;
            case 'turn-left':
                iconName = 'west';
                break;
            case 'turn-slight-right':
                iconName = 'call_made';
                break;
            case 'turn-sharp-right':
                iconName = 'double_arrow';
                break;
            case 'turn-right':
                iconName = 'east';
                break;
            case 'straight':
                iconName = 'north';
                break;
            default:
                iconName = 'arrow_upward';
                break;
        }

        return <span style={{flex: 1, position: 'relative', left: -5, color: '#2F2A85'}} className="material-icons">{iconName}</span>;
    };

    if (directionsResponse?.routes) {
        return (
            <div style={{marginTop: 5}} className="routeOptionContainer">
                <div className="routeDetailOption">
                    <div style={{padding: 20, paddingBottom: 15}}>
                        <div className="routeDetail">
                            <span className="routeDetailSummary">
                                {directionsResponse.routes[routeIndex]?.legs[0]?.duration?.text} ({directionsResponse.routes[routeIndex]?.legs[0]?.distance?.text})
                            </span>

                            <div style={{flexDirection: 'row', display: 'flex', position: 'absolute', right: 20}}>
                                <button
                                    onClick={onShareClick}
                                    style={{
                                        backgroundColor: '#FCBC00',
                                        borderRadius: 8,
                                        border: 'none',
                                        height: 25,
                                        width: 25,
                                        cursor: 'pointer',
                                        justifyContent: 'center',
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={require('../assets/share.png')} alt="Share" style={{height: 14}} />
                                </button>

                                <button
                                    onClick={onEmailClick}
                                    style={{
                                        backgroundColor: '#FCBC00',
                                        borderRadius: 8,
                                        border: 'none',
                                        height: 25,
                                        width: 25,
                                        cursor: 'pointer',
                                        justifyContent: 'center',
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: 10
                                    }}
                                >
                                    <img src={require('../assets/email.png')} alt="Email" style={{height: 14}} />
                                </button>
                            </div>
                        </div>
                        <div style={{marginTop: 5}} className="routeDetail">
                            <span className="routeDetailStations">Por {directionsResponse.routes[routeIndex]?.summary}</span>
                        </div>
                        <div style={{marginTop: 5, opacity: 0.7}} className="routeDetail">
                            <span className="routeDetailStations">{stationsQuantity?.quantity} estaciones OnTurtle</span>
                        </div>
                        <div className={'divider'}></div>
                    </div>
                    <div onClick={() => setIsOpen(!isOpen)} style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', cursor: 'pointer', padding: '10px 20px'}}>
                        <span style={{fontSize:10, color:'rgba(47, 42, 133, 1)', textAlign:'center'}}>{ isOpen ? 'Ocultar detalles' : "Mostrar detalles"}</span>
                        <img
                            style={{
                                width: 15,
                                marginLeft: 10,
                                marginTop:5,
                                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.3s ease'
                            }}
                            src={require('../assets/chevronDownBlue.png')}
                            alt="Chevron Icon"
                        />
                    </div>
                    {isOpen && (
                        <div style={{height: '350px', overflowY: 'auto'}} className="custom-scrollbar">
                            {directionsResponse.routes[routeIndex]?.legs[0].steps.map((step: any, index: number) => (
                                <div style={{paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10}} key={index}>
                                    <div className="routeDetail">
                                        {getManeuverIcon(step.maneuver)}
                                        <span style={{textAlign: 'start', fontSize: 12, color: '#2F2A85', fontWeight: 'normal', marginRight: 5, flex: 7}} dangerouslySetInnerHTML={{__html: step.instructions}}></span>
                                        <span style={{color: '#FCBC00', flex: 1, textAlign: 'right', fontSize: 12}}>{step.distance?.text}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default RouteDetail;
