import React, {useEffect, useMemo, useState} from 'react';
import {DirectionsRenderer, GoogleMap, LoadScript, Marker, useLoadScript} from '@react-google-maps/api';
import {API_KEY} from "../utils";
import {
    getStation,
    getStations,
    getStationsByCountry, getStationsBySearch,
    getStationsByServices,
    getStationsByServicesAndCountry, sendEmail
} from "../apiCalls/apiCalls";
import {Station, StationDetail} from "../types/types";
import {MAP_CENTER, mapOptions, MAP_ZOOM} from "./mapStyle";
import {StationDetailModal} from "../components/StationDetailModal";
import {RoutesModal} from "../components/routesModal";
import {SelectStationModal} from "../components/SelectStationModal";
import {RoutesSelectedModal} from "../components/routesSelectedModal";
import RouteDetail from "../components/RouteDetail";
import SelectRadius from "../components/SelectRadius";

const specialStationIcon = require('../assets/specialStation.png');
const stationIcon = require('../assets/station.png');
const naturalGasStationIcon = require('../assets/naturalGasStation.png');

const libraries: any = ['places'];

function Map() {
    const [directionsResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult | null>(null);
    const [routeIndex, setRouteIndex] = useState<number>(0);
    const [showRouteDetail, setShowRouteDetail] = useState(false);
    const [showShareRouteLink, setShowShareRouteLink] = useState(false);
    const [showShareStationLink, setShowShareStationLink] = useState(false);
    const [showSendEmail, setShowSendEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [stations, setStations] = useState<Station[]>([]);
    const [allStations, setAllStations] = useState<Station[]>([]);
    const [selectedStation, setSelectedStation] = useState<StationDetail | undefined>();
    const [origin, setOrigin] = useState('')
    const [destination, setDestination] = useState('')
    const [stops, setStops] = useState<string[]>([]);
    const [isStationModalOpen, setIsStationModalOpen] = useState(true);
    const [stationsPerRoute, setStationsPerRoute] = useState<{index: number, quantity: number}[]>([]);
    const [radius, setRadius] = useState<number>(10);

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: API_KEY,
        libraries: libraries
    })

    useEffect(() => {
        loadStations();
    }, []);

    const loadStations = async () => {
        setIsLoading(true)

        try {
            const fetchedStations = await getStations();
            setStations(fetchedStations);
            setAllStations(fetchedStations);
        } catch (error) {
            console.error('Error loading stations:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const loadStationsByServices = async (services: string[]) => {
        setIsLoading(true)

        try {
            const fetchedStations = await getStationsByServices(services);
            setStations(fetchedStations);
            setAllStations(fetchedStations);
        } catch (error) {
            console.error('Error loading stations:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const loadStationsByCountries = async (countries: string[]) => {
        setIsLoading(true)

        try {
            const responses = await Promise.all(countries.map((country) => getStationsByCountry(country)));
            const allTheCountries: Station[] = []

            responses.forEach((res) => {
                res.forEach((item: Station | null) => {
                    if (item !== null) {
                        allTheCountries.push(item)
                    }
                })
            })

            setStations(allTheCountries)
            setAllStations(allTheCountries);
        } catch (error) {
            console.error('Error loading stations:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const loadStationsByCountriesAndServices = async (countries: string[], services: string[]) => {
        setIsLoading(true)

        try {
            const responses = await Promise.all(countries.map((country) => getStationsByServicesAndCountry(services, country)));
            const allTheCountries: Station[] = []

            responses.forEach((res) => {
                res.forEach((item: Station | null) => {
                    if (item !== null) {
                        allTheCountries.push(item)
                    }
                })
            })

            setStations(allTheCountries);
            setAllStations(allTheCountries);
        } catch (error) {
            console.error('Error loading stations:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const loadStationsBySearch = async (type: string, query: string) => {
        setIsLoading(true)

        try {
            const fetchedStations = await getStationsBySearch(type, query);
            setStations(fetchedStations);
            setAllStations(fetchedStations);
        } catch (error) {
            console.error('Error loading stations:', error);
        } finally {
            setIsLoading(false)
        }
    };

    // ACA generate route
    useEffect(() => {
        if (!isLoaded || destination === '' || origin === '') return;

        const directionsService = new google.maps.DirectionsService();

        const waypoints = stops.map(stop => ({ location: stop, stopover: true }));

        waypoints.unshift({location: destination, stopover: true})

        directionsService.route({
            origin: origin,
            destination: waypoints[waypoints.length -1].location,
            waypoints: waypoints,
            optimizeWaypoints: false,
            travelMode: google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: true,
        }, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                setDirectionsResponse(result);
                calculateStations(result);
            } else {
                console.error(`Error fetching directions ${result}`);
            }
        });
    }, [origin, destination, stops, isLoaded, allStations, radius]);

    const calculateStations = (directionsResponseProp: google.maps.DirectionsResult | null, newCurrentIndex: number = routeIndex) => {
        if (!directionsResponseProp) return;

        const stationsPerRouteCounter: {index: number, quantity: number}[] = []

        for (let i = 0; i < directionsResponseProp.routes.length; i++) {
            const path = directionsResponseProp.routes[i]?.overview_path;
            if(!path) continue;

            const stationsToShow: Station[] = [];

            const pathPoints = path.map(point => new google.maps.LatLng(point.lat(), point.lng()));

            allStations.forEach(station => {
                const stationLatLng = new google.maps.LatLng(parseFloat(station.lat), parseFloat(station.lng));

                const isWithinDistance = pathPoints.some(point =>
                    google.maps.geometry.spherical.computeDistanceBetween(point, stationLatLng) <= radius * 1000
                );

                if (isWithinDistance) {
                    stationsToShow.push(station);
                }
            });

            stationsPerRouteCounter.push({index: i, quantity: stationsToShow.length})

            if(i === newCurrentIndex){
                setStations(stationsToShow)
            }
        }

        setStationsPerRoute(stationsPerRouteCounter)
    }

    const renderRouteOptions = () => {
        if (directionsResponse?.routes) {
            return <div className="routeOptionContainer">
                {directionsResponse.routes.map((route, index) => (
                    <div className="routeOption" key={index} onClick={() => {
                        setRouteIndex(index);
                        setShowRouteDetail(true);
                        setIsStationModalOpen(false)
                        calculateStations(directionsResponse, index)
                    }}>
                        <div className="routeDetail">
                            <span className="routeDetailSummary">Por {route.summary}</span>
                            <span className="routeDetailDuration">{route?.legs[0]?.duration?.text}</span>
                        </div>
                        <div style={{marginTop: 5}} className="routeDetail">
                            <span className="routeDetailStations">{stationsPerRoute[index].quantity} estaciones onTurtle</span>
                            <span className="routeDetailDistance">{route?.legs[0]?.distance?.text}</span>
                        </div>
                    </div>
                ))}
            </div>;
        }
    };

    const getMarketIcon = (station: Station) => {
        const iconSize = new google.maps.Size(16.5, 22)
        if (station.serveis.includes('GSL') || station.serveis.includes('GNC')) {
            return {
                url: naturalGasStationIcon,
                scaledSize: iconSize
            }
        }

        return station.special
            ? {
                // Changed icon because feedback client
                // url: specialStationIcon,
                url: stationIcon,
                scaledSize: iconSize
            }
            : {
                url: stationIcon,
                scaledSize: iconSize
            }
    }

    const handleOnStationModalChange = async (countries: string[], services: string[]) => {
        if (countries.length === 0 && services.length === 0) {
            return await loadStations()
        }

        if (countries.length !== 0 && services.length === 0) {
            return await loadStationsByCountries(countries)
        }

        if (countries.length === 0 && services.length !== 0) {
            return await loadStationsByServices(services)
        }

        if (countries.length !== 0 && services.length !== 0) {
            return await loadStationsByCountriesAndServices(countries, services)
        }
    }

    const handleOnSearch = async (type: string, query: string) => {
        await loadStationsBySearch(type, query);
    };

    if (!isLoaded) {
        return <div className="loading-overlay">
            <div className="loading-spinner"></div>
        </div>
    }
    console.log('directionsResponse', directionsResponse)
    const handleOriginChange = (selected: string)=>{
        setShowRouteDetail(false);
        setDirectionsResponse(null);
        setOrigin(selected)
        if(!selected){
            setStations(allStations)
        }
    }

    const handleDestinationChange = (selected: string)=>{
        setShowRouteDetail(false);
        setDirectionsResponse(null);
        setDestination(selected)
        setIsStationModalOpen(false)

        if(!selected){
            setStations(allStations)
        }
    }

    return (
        <>
            <GoogleMap
                mapContainerClassName={'mapContainer'}
                center={MAP_CENTER}
                zoom={MAP_ZOOM}
                options={mapOptions}
            >
                {isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner"></div>
                    </div>
                )}


                {directionsResponse && (
                    <DirectionsRenderer
                        key={`google-map-${routeIndex}`}
                        directions={directionsResponse}
                        routeIndex={routeIndex}
                        options={{
                            routeIndex: routeIndex,
                            polylineOptions: {
                                strokeColor: '#3669a3',
                                strokeWeight: 4,
                                strokeOpacity: 1
                            }
                        }}
                    />
                )}

                {stations.map(station => (
                    <Marker
                        onClick={async (e) => {
                            const stationDetailResponse = await getStation(station.id)
                            setSelectedStation(stationDetailResponse)
                        }}
                        cursor={'pointer'}
                        key={station.id}
                        position={{lat: parseFloat(station.lat), lng: parseFloat(station.lng)}}
                        icon={getMarketIcon(station)}
                    />
                ))}
            </GoogleMap>

            <StationDetailModal onShareClick={() => setShowShareStationLink(!showShareStationLink)}  station={selectedStation} onClose={() => {
                setSelectedStation(undefined)
                setShowShareStationLink(false)
            }}/>

            <div className={'routes-modal-wrapper'}>
                {showRouteDetail ?
                    <>
                        <RoutesSelectedModal onBackPress={() => {
                            setShowShareRouteLink(false)
                            setShowSendEmail(false)
                            setShowRouteDetail(false)
                        }} origin={origin} destination={destination}/>
                        <SelectRadius radius={radius} setRadius={setRadius} />

                        <RouteDetail onEmailClick={()=> setShowSendEmail(!showSendEmail)} onShareClick={()=> setShowShareRouteLink(!showShareRouteLink)} directionsResponse={directionsResponse} routeIndex={routeIndex}
                                     stationsQuantity={stationsPerRoute[routeIndex]}/>
                    </>
                    :
                    <>
                        <RoutesModal origin={origin} destination={destination} setOrigin={handleOriginChange}
                                     setDestination={handleDestinationChange}
                                     stops={stops}
                                     setStops={setStops}
                        />
                        {renderRouteOptions()}
                    </>
                }

                <SelectStationModal isStationModalOpen={isStationModalOpen}
                                    setIsStationModalOpen={setIsStationModalOpen} onSearch={handleOnSearch}
                                    onChange={handleOnStationModalChange}/>
            </div>

            {showShareRouteLink && (
                <div className={'share-modal-wrapper'}>
                    <div className={'shareContainer'}>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',marginBottom:10}}>
                            <div style={{height:25, width:25, margin:10}} />
                            <p style={{fontWeight:'bold', color:'#2F2A85'}}>Compartir la ruta</p>
                            <div style={{height:25, width:25, backgroundColor:'#2F2A85', justifyContent:'center', alignItems:'center', margin:10, display:'flex', borderRadius:5, cursor:'pointer'}}
                                 onClick={() => {
                                     setShowShareRouteLink(false)
                                 }}
                            >
                                <span style={{color:'white'}}>x</span>
                            </div>
                        </div>
                        <span style={{color:'#2F2A85'}}>Visualice y comparta la ruta en Google Maps:</span>
                        <br />

                        <div style={{backgroundColor:'#CECDE8', padding:10, borderRadius:10,marginTop:15}}>
                            <a style={{wordBreak: 'break-all', color: '#2F2A85', fontWeight:'bold'}}
                               href={`https://www.google.com/maps/dir/${origin}/${destination}`}
                               target="_blank">{`https://www.google.com/maps/dir/${origin}/${destination}`}</a>

                        </div>
                    </div>
                </div>
            )}

            {showShareStationLink && (
                <div className={'share-modal-wrapper'}>
                    <div className={'shareContainer'}>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',marginBottom:10}}>
                            <div style={{height:25, width:25, margin:10}} />
                            <p style={{fontWeight:'bold', color:'#2F2A85'}}>Compartir estación</p>
                            <div style={{
                                height: 25,
                                width: 25,
                                backgroundColor: '#2F2A85', justifyContent:'center', alignItems:'center', margin:10, display:'flex', borderRadius:5, cursor:'pointer'}}
                                 onClick={() => {
                                     setShowShareStationLink(false)
                                 }}
                            >
                                <span style={{color:'white'}}>x</span>
                            </div>
                        </div>
                        <span style={{color: '#2F2A85'}}>Visualice y comparta la estación en Google Maps:</span>
                        <br/>
                        <div style={{backgroundColor:'#CECDE8', padding:10, borderRadius:10,marginTop:15}}>

                        <a style={{wordBreak: 'break-all', color: '#2F2A85', fontWeight:'bold'}}
                           href={`https://maps.google.com/maps?q=${selectedStation?.lat},${selectedStation?.lng}`} target="_blank">{`https://maps.google.com/maps?q=${selectedStation?.lat},${selectedStation?.lng}`}</a>
                        </div>

                    </div>
                </div>
            )}

            {showSendEmail && (
                <div className={'share-modal-wrapper'}>
                    <div className={'shareContainer'}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 10
                        }}>
                            <div style={{height: 25, width: 25, margin: 10}}/>
                            <p style={{fontWeight: 'bold', color:'#2F2A85'}}>Enviar ruta por email</p>
                            <div style={{
                                height: 25,
                                width: 25,
                                backgroundColor: '#2F2A85',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: 10,
                                display: 'flex',
                                borderRadius: 5,
                                cursor: 'pointer'
                            }}
                                 onClick={() => {
                                     setShowSendEmail(false)
                                 }}
                            >
                                <span style={{color: 'white'}}>x</span>
                            </div>
                        </div>
                        <form onSubmit={async (e) => {
                            e.preventDefault();

                            const form = e.target as HTMLFormElement;
                            const formData = new FormData(form);
                            const name = formData.get('name') as string;
                            const email = formData.get('email') as string;
                            const indications = formData.get('indicaciones') as string;
                            const recommend = formData.get('recomendacion') === 'on';
                            const link = `https://www.google.com/maps/dir/${origin}/${destination}`
                            await sendEmail(link, name, email, origin, destination, indications, recommend ? 1 : 0)

                            alert('Ruta enviada')
                            setShowSendEmail(false)

                        }}>
                            <div style={{width: '100%', marginBottom: 10}}>
                                <input required style={{width: '100%', borderRadius:8, borderWidth:0, backgroundColor:'#E4E4F3', padding: 8}} type="text" placeholder="Ingresar nombre" name="name"/>
                            </div>
                            <div style={{width: '100%', marginBottom: 10}}>
                                <input required style={{width: '100%', borderRadius:8, borderWidth:0, backgroundColor:'#E4E4F3', padding: 8}}  type="email" placeholder="Ingresar email" name="email"/>
                            </div>
                            <div style={{width: '100%', marginBottom: 10}}>
                                <input required value={origin + '/' + destination} style={{width: '100%', borderRadius:8, borderWidth:0, backgroundColor:'#CECDE8', padding: 8}} type="text"
                                       placeholder="Ingresar instrucciones" name="indicaciones"/>
                            </div>
                            <div style={{width: '100%', marginBottom: 10, flexDirection: 'row', display: 'flex', marginLeft:15, alignItems:'center'}}>
                                <input style={{width: 25}} type="checkbox" name="recomendacion"/>

                                <label style={{display: 'block', color:'#2F2A85', fontSize:12}}>Recomendación</label>
                            </div>
                            <button type="submit" style={{
                                marginTop: 30,
                                backgroundColor: '#FCBC00',
                                paddingLeft:30,
                                paddingRight:30,
                                paddingTop:10,
                                paddingBottom:10,
                                borderRadius:15,
                                borderWidth:0,
                                color:'#2F2A85'
                            }}>
                                Enviar
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default Map;
