import {Station, StationDetail} from "../types/types";

export async function getStations(): Promise<Station[]> {
    try {
        const response = await fetch('https://onturtle.eu/api/estacions.php?lang=es');
        if (!response.ok) {
            throw new Error('Error al realizar la solicitud');
        }

        return await response.json();
    } catch (error) {
        console.error('Error en obtenerEstaciones:', error);
        throw error;
    }
}

export async function getStationsByCountry(countryCode: string): Promise<Station[]> {
    try {
        const response = await fetch(`https://onturtle.eu/api/estacions.php?lang=es&p=${countryCode}`);
        if (!response.ok) {
            throw new Error('Error al realizar la solicitud');
        }

        return await response.json();
    } catch (error) {
        console.error('Error en obtenerEstaciones:', error);
        throw error;
    }
}

export async function getStation(stationId: string): Promise<StationDetail> {
    try {
        const response = await fetch(`https://onturtle.eu/api/estacions.php?id=${stationId}&lang=es`);
        if (!response.ok) {
            throw new Error('Error al realizar la solicitud');
        }

        return await response.json();
    } catch (error) {
        console.error('Error en obtenerEstaciones:', error);
        throw error;
    }
}

export async function getStationsByServices(services: string[]): Promise<Station[]> {
    try {
        const filters = services.map(service => `f[]=${service}`).join('&');
        const url = `https://onturtle.eu/api/estacions.php?lang=es&${filters}`;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Error al realizar la solicitud');
        }

        return await response.json();
    } catch (error) {
        console.error('Error en getStationsByServices:', error);
        throw error;
    }
}

export async function getStationsByServicesAndCountry(services: string[], countryCode: string): Promise<Station[]> {
    try {
        const filters = services.map(service => `f[]=${service}`).join('&');
        const url = `https://onturtle.eu/api/estacions.php?lang=es&${filters}&p=${countryCode}`;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Error al realizar la solicitud');
        }

        return await response.json();
    } catch (error) {
        console.error('Error en getStationsByServices:', error);
        throw error;
    }
}

export async function getCountries(): Promise<any> {
    try {
        const response = await fetch('https://onturtle.eu/api/paisos.php?lang=es')

        if (!response.ok) {
            throw new Error('Error al realizar la solicitud');
        }

        return await response.text();
    } catch (error) {
        console.error('Error en getCountries:', error);
        throw error;
    }
}

export async function getServices(): Promise<any> {
    try {
        const response = await fetch('https://onturtle.eu/api/servicios.php?lang=es')

        if (!response.ok) {
            throw new Error('Error al realizar la solicitud');
        }

        return await response.text();
    } catch (error) {
        console.error('Error en getCountries:', error);
        throw error;
    }
}

export async function getStationsBySearch(type:string, query:string): Promise<Station[]> {
    try {
        const url = `https://onturtle.eu/api/cerca.php?s=${query}&camp=${type}`;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Error al realizar la solicitud');
        }

        return await response.json();
    } catch (error) {
        console.error('Error en getStationsByServices:', error);
        throw error;
    }
}

export async function sendEmail(link: string, name: string, email: string, origin: string, destination: string, description: string, recommend: number) {
    try {
        const formdata = new FormData();
        formdata.append("ruta", link);
        formdata.append("nom", name);
        formdata.append("email", email);
        formdata.append("origen-desti", `${origin}, ${destination}`);
        formdata.append("descripcio", description);
        formdata.append("recomanar", recommend?.toString());

        const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow"
        };

        // @ts-ignore
        fetch("https://onturtle.eu/api/envia-ruta.php", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
    } catch (error) {
        console.error('Error al enviar email:', error);
        throw error;
    }
}
