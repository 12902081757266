import {StationDetail} from "../types/types";
import React from "react";

interface StationDetailModalProps {
    station: StationDetail | undefined,
    onClose: () => void,
    onShareClick: () => void
}

export const StationDetailModal: React.FC<StationDetailModalProps> = ({station, onClose, onShareClick}) => {
    if (!station) return <div></div>

    const formatSchedules = (schedules: string) => {
        if(!schedules) return ''

        const schedulesArray = schedules.split('/')

        return schedulesArray.map(time => {
            return (
                <div style={{marginTop:20, textAlign:'right'}}>
                   <span style={{fontSize: 14, color: '#2F2A85', fontWeight:700}}>{time.trim()}</span>
                </div>
            )
        })
    }

    const wrapSpansAfterH4WithDiv = (htmlString: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const h4Elements = doc.querySelectorAll('h4');

        h4Elements.forEach(h4 => {
            const containerDiv = doc.createElement('div');
            containerDiv.className = 'cat-serv-wrapper';

            let nextElement = h4.nextElementSibling;
            while (nextElement && nextElement.tagName.toLowerCase() !== 'h4') {
                const span = nextElement;
                nextElement = nextElement.nextElementSibling;
                containerDiv.appendChild(span);
            }

            h4?.parentNode?.insertBefore(containerDiv, h4.nextSibling);
        });

        return doc.body.innerHTML;
    };

    const updatedHtmlString = wrapSpansAfterH4WithDiv(station.serveis);

    return (
        <div className={'station-detail-container'}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <img onClick={onShareClick} style={{height: 20, width: 20, marginRight: 10, cursor:"pointer"}} src={require('../assets/shareIcon.png')}/>
                    <a href={`http://maps.google.com/maps?q=&layer=c&cbll=${station.lat},${station.lng}`} target="_blank">
                        <img style={{height: 20, width: 20, cursor: 'pointer'}}
                             src={require('../assets/streetViewIcon.png')}/>

                    </a>
                </div>
                <img onClick={onClose} style={{height: 15, width: 15, cursor: 'pointer'}}
                     src={require('../assets/closeIcon.png')}/>
            </div>

            <div style={{marginTop: 20, textAlign: 'left'}}>
                <span style={{fontWeight: 700, fontSize: 20, color: '#2F2A85'}}>{station.id}: </span>
                <span style={{fontSize: 20, color: '#2F2A85'}}>{station.nom}</span>
            </div>

            <p style={{fontSize: 14, color: '#2F2A85', textAlign: 'left'}}>{station.add}</p>

            <div className={'divider'}></div>

            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <div style={{marginTop: 20, textAlign: 'left'}}>
                        <span style={{fontSize: 14, color: '#2F2A85'}}>Tel: </span>
                        <span style={{fontSize: 14, color: '#2F2A85',fontWeight: 700}}>{station.tel ?? '-'}</span>
                    </div>
                    <div style={{marginTop: 20, textAlign: 'left'}}>
                        <span style={{fontSize: 14, color: '#2F2A85'}}>Lat: </span>
                        <span style={{fontSize: 14, color: '#2F2A85',fontWeight: 700 }}>{station.lat ?? '-'}</span>
                    </div>
                    <div style={{marginTop: 20, textAlign: 'left'}}>
                        <span style={{ fontSize: 14, color: '#2F2A85'}}>Lng: </span>
                        <span style={{fontSize: 14, color: '#2F2A85',fontWeight: 700}}>{station.lng ?? '-'}</span>
                    </div>
                </div>
                <div style={{flex: 1}}>
                   {formatSchedules(station.info)}
                </div>
            </div>

            <div className={'divider'}></div>
            <div dangerouslySetInnerHTML={{ __html: updatedHtmlString }} />
        </div>
    )
}
