import React, {useEffect, useState} from "react";
import SearchInput from "./SearchInput";
import {getCountries, getServices} from "../apiCalls/apiCalls";
import SearchSelectInput from "./SearchSelectInput";
import { Tooltip } from 'antd';

interface Country {
    name: string;
    code: string;
    image: string;
}

interface Service {
    name: string;
    code: string;
    image: string;
    category: string;
}

interface SelectStationModalProps {
    onChange: (countries: string[], services: string[]) => void;
    onSearch: (type: string, query: string) => void;
    setIsStationModalOpen: (open: boolean) => void;
    isStationModalOpen: boolean;
}

export const SelectStationModal: React.FC<SelectStationModalProps> = ({onChange, onSearch, isStationModalOpen, setIsStationModalOpen}) => {
    const [countries, setCountries] = useState<Country[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [selectedCountriesCode, setSelectedCountriesCode] = useState<string[]>([]);
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [showMoreServices, setShowMoreServices] = useState(false);

    useEffect(() => {
        const fetchCountries = async () => {
            const countriesHTML = await getCountries();
            const parser = new DOMParser();
            const doc = parser.parseFromString(countriesHTML, "text/html");
            const countryElements = doc.querySelectorAll('span[title]');
            const extractedCountries = Array.from(countryElements)
                .filter(el => el.querySelector('img')?.getAttribute('src'))
                .map(el => ({
                    name: el.getAttribute('title') || '',
                    code: el.getAttribute('data-pais') || '',
                    image: `https://onturtle.eu${el.querySelector('img')?.getAttribute('src') || ''}`
                }))
                .filter(country => country.image !== '');

            setCountries(extractedCountries);
        };

        const fetchServices = async () => {
            const servicesHTML = await getServices();
            const parser = new DOMParser();
            const doc = parser.parseFromString(servicesHTML, "text/html");

            const extractedServices: Service[] = [];
            let currentCategory = '';

            const excludedFiltreCodes = [
                "ADR", "BAR", "CVI", "ENG", "HAB", "NEU", "PMF", "REC", "SDE", "TEL", "TRC", "GCA", "PSE"
            ];

            doc.body.childNodes.forEach(node => {
                if (node.nodeType === Node.ELEMENT_NODE) {
                    const element = node as Element;
                    if (element.tagName === 'H4') {
                        currentCategory = element.textContent || '';
                    }
                    if (element.tagName === 'SPAN' && element.hasAttribute('title')) {
                        const img = element.querySelector('img');
                        if (img && img.getAttribute('src')) {
                            extractedServices.push({
                                name: element.getAttribute('title') || '',
                                code: element.getAttribute('data-filtre') || '',
                                image: `https://onturtle.eu${img.getAttribute('src') || ''}`,
                                category: currentCategory
                            });
                        }
                    }
                }
            });

            const filterServices = extractedServices.filter(service =>{
                return !excludedFiltreCodes.includes(service.code)
            })

            setServices(filterServices);
        };

        fetchServices();
        fetchCountries();
    }, []);

    useEffect(() => {
        onChange(selectedCountriesCode, selectedServices)
    }, [selectedServices, selectedCountriesCode]);

    const handleCountryOnClick = (country: Country) => {
        if (selectedCountriesCode.includes(country.code)) {
            setSelectedCountriesCode(selectedCountriesCode.filter(item => item !== country.code));
        } else {
            setSelectedCountriesCode([...selectedCountriesCode, country.code]);
        }
    };

    const handleServicesOnClick = (service: Service) => {
        if (selectedServices.includes(service.code)) {
            setSelectedServices(selectedServices.filter(item => item !== service.code));
        } else {
            setSelectedServices([...selectedServices, service.code]);
        }
    };

    const groupedServices = services.reduce((acc, service) => {
        if (!acc[service.category]) {
            acc[service.category] = [];
        }
        acc[service.category].push(service);
        return acc;
    }, {} as Record<string, Service[]>);

    return (
        <div className={'select-station-modal-container'}>
            <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between', paddingRight:20}}>
                <div style={{width: 24}}/>
                <p style={{color: 'white', fontSize: 20, fontWeight: 700}}>Estaciones</p>

                {isStationModalOpen ?
                    <div onClick={() => setIsStationModalOpen(false)} className={'station-modal-button'}>
                        <img src={require('../assets/chevronUp.png')} style={{height: 6, width: 14}}/>
                    </div>
                    :
                    <div onClick={() => setIsStationModalOpen(true)} className={'station-modal-button'}>
                        <img src={require('../assets/chevronDown.png')} style={{height: 6, width: 14}}/>
                    </div>}
            </div>

            {isStationModalOpen ? (
                <>
                    <div className={'divider-white'}/>

                    <div style={{paddingRight:20}}>
                        <SearchSelectInput onSearch={onSearch}/>
                    </div>

                    <div className="select-scrollbar">
                        <h4 className={'category-title'}>País</h4>

                        <div className="countries-container">
                            {countries.map((country) => (
                                <div
                                    onClick={() => handleCountryOnClick(country)}
                                    key={country.code}
                                    className="country-item"
                                >
                                    <div className="image-wrapper">
                                        <img
                                            src={country.image}
                                            alt={country.name}
                                            className={
                                                selectedCountriesCode.includes(country.code)
                                                    ? 'country-image-selected'
                                                    : 'country-image'
                                            }
                                        />
                                        {selectedCountriesCode.includes(country.code) && (
                                            <img
                                                src={require('../assets/selected.png')}
                                                alt="selected"
                                                className="selected-icon"
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {Object.entries(groupedServices).map(([category, services]) => (
                            <div key={category}>
                                <h4 className={'category-title'}>{category}</h4>
                                <div className="services-container">
                                    {services.slice(0, showMoreServices ? 8 : services.length).map((service) => (
                                        <div
                                            onClick={() => handleServicesOnClick(service)}
                                            key={service.code}
                                            className="service-item"
                                        >
                                            <Tooltip title={service.name}>
                                                <div className="image-wrapper">
                                                    <img
                                                        src={service.image}
                                                        alt={service.name}
                                                        className={
                                                            selectedServices.includes(service.code)
                                                                ? 'service-image-selected'
                                                                : 'service-image'
                                                        }
                                                    />
                                                    {selectedServices.includes(service.code) && (
                                                        <img
                                                            src={require('../assets/selected.png')}
                                                            alt="selected"
                                                            className="selected-icon"
                                                        />
                                                    )}
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}
        </div>
    );
};
