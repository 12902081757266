const mapStyles = [
    {
        featureType: "administrative.neighborhood",
        elementType: "labels.icon",
        stylers: [{visibility: "off"}],
    },
    {
        featureType: "administrative",
        elementType: "labels.text",
        stylers: [{visibility: "simplified"}],
    },
    {
        featureType: "poi",
        stylers: [{visibility: "off"}]
    },
    {
        featureType: "landscape.natural.terrain",
        elementType: "geometry",
        stylers: [{visibility: "off"}],
    },
    {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{color: "#b9d4f1"}],
    },
    {
        featureType: "administrative",
        elementType: "labels.text.stroke",
        stylers: [{color: "#ffffff"}, {weight: 0.3}],
    },
    {
        featureType: "landscape",
        stylers: [{color: "#FEF3D9"}]
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{visibility: "off"}],
    },
    {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers: [{color: "#808080"}, {weight: 1}, {opacity: 0.8}],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{visibility: "off"}],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{weight: 0.9}, {color: "#d1cda5"}],
    },
];

export const mapOptions: google.maps.MapOptions = {
    styles: mapStyles,
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    gestureHandling: 'cooperative',
}

export const MAP_CENTER = {
    lat: 44.912304,
    lng: 5.229492
};

export const MAP_ZOOM = 5
