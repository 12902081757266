import './App.css';
import Map from "./map/Map";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

function App() {
    const urlParams = new URLSearchParams(window.location.search);
    const currentLanguage = urlParams.get('lng') || 'ES';
    const {i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(currentLanguage);
    }, [currentLanguage]);

  return (
    <div className="App">
      <Map />
    </div>
  );
}

export default App;
