import React, {useState} from "react";
import AutocompleteInput from "./AutocompleteInput";
import { useTranslation } from 'react-i18next';

interface RoutesModalProps {
    setOrigin: (value: string) => void;
    setDestination: (value: string) => void;
    setStops: (value: string[]) => void;
    origin: string;
    destination: string;
    stops: string[];
}

export const RoutesModal: React.FC<RoutesModalProps> = ({setOrigin, setDestination, setStops, origin, destination, stops}) => {
    const { t, i18n } = useTranslation();

    const addStop = () => {
        setStops([...stops, ""]);
    };

    const removeStop = (index: number) => {
        const newStops = stops.filter((_, i) => i !== index);
        setStops(newStops);
    };

    const updateStop = (index: number, value: string) => {
        const newStops = [...stops];
        newStops[index] = value;
        setStops(newStops);
    };

    return (
        <div className={'routes-modal-container'}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center', marginBottom:10}}>
                {/*<img style={{height: 55}} src={require('../assets/routesSearchPoints.png')}/>*/}
                <div style={{display:'flex', flexDirection:'column', flex:1, marginLeft:0, marginRight:10}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                        <img style={{width: 20, marginRight: 5, marginTop:10}} src={require('../assets/originIcon.png')} />
                        <div style={{flex: 1}}>
                            <AutocompleteInput value={origin} onPlaceSelected={(value) => {
                                setOrigin(value.formatted_address)
                            }} placeholder={t('punto_origen')}/>
                        </div>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                        <img style={{ width: 20, marginRight: 5, marginTop:-15}} src={stops.length === 0 ? require('../assets/destinationIcon.png') : require('../assets/stopIcon.png')} />
                        <div style={{flex: 1}}>
                            <AutocompleteInput value={destination} onPlaceSelected={(value) => {
                                setDestination(value.formatted_address)
                            }} placeholder={'Punto de destino'} />
                        </div>
                    </div>

                    {stops.map((stop, index) => (
                        <div key={index} style={{position: 'relative', display: 'flex', alignItems: 'center', marginBottom: 10}}>
                            <img style={{ width: 20, marginRight: 5, marginTop:-15}}
                                 src={stops.length-1 === index ? require('../assets/destinationIcon.png') : require('../assets/stopIcon.png')}/>
                            <div style={{flex: 1}}>
                                <AutocompleteInput
                                    value={stop}
                                    onPlaceSelected={(value) => updateStop(index, value.formatted_address)}
                                    placeholder={`Parada ${index + 1}`}
                                />
                            </div>
                            <div onClick={() => removeStop(index)} style={{marginLeft: 0, position: 'absolute', right: -22, top: 17, cursor: 'pointer'}}>
                                <img style={{height:15, width:15}} src={require('../assets/closeStop.png')} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div onClick={addStop} style={{marginTop: 10, display: 'flex', alignItems: 'center', cursor: 'pointer',marginLeft:5}}>
                <img style={{height: 18, width: 18, marginRight: 5}} src={require('../assets/addStop.png')} />
                <span style={{marginLeft:5, fontSize:15, color:'rgba(47, 42, 133, 0.5)'}}>Añadir destino</span>
            </div>
        </div>
    );
};
